<template>
  <v-col
    cols="12"
    sm="12"
  >
    <!--Title Bar-->
    <title-bar :title-value="'FAQ'" />

    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--Title-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="title"
                    label="Title"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="faqsStore.faqs.data && faqsStore.faqs.data.length > 0 && faqsStore.faqs.last_page > 1 ? true : false"
        :current-page="faqsStore.faqs.current_page"
        :last-page="faqsStore.faqs.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Entry Dialog-->
      <faq />
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="faqsStore.faqs.data && faqsStore.faqs.data.length > 0 ? true : false"
        :list-data="faqsStore.faqs.data"
        :title="'FAQ'"
        :section="7"
        :button-title="'FAQ'"
        :actions="actions"
        @open-entry="openEntry($event)"
      />
    </div>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import TitleBar from '@/components/TitleBar';
import Faq from '@/components/FAQ';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';

const { mapFields } = createHelpers({
  getterType: 'faqsStore/getFilterField',
  mutationType: 'faqsStore/updateFilterField',
});

export default {
  name: 'SettingsFAQ',
  components: {
    TitleBar,
    Faq,
    Pagination,
    ListBasic
  },
  data() {
    return {
      filterMenu: false,
      actions: {
        menu:0,
        view:1,
        edit:1,
        password:0,
        invite:0,
        email:0
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: state => state.progressStore,
      faqsStore: state => state.faqsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'title',
      'flag_status',
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true)
	    /* Grab filters */
	    const filters = this.$store.getters['faqsStore/getFilters'];
      /* Grab faqs based on active filters */
      await this.$store.dispatch('faqsStore/fetch', filters);
      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
	  },

	  async clearFilter() {
		  this.filterMenu = false;
      this.title = '';
      this.flag_status = '';
      this.page = 1;
		  this.initialize();
	  },

	  async openEntry(id) {
		  await this.$store.dispatch('faqsStore/entry', id);
    },
    
    showFiltered(){
      if(this.code || this.managing_po || this.id_cohort || this.title || (this.flag_status === 0 || this.flag_status === 1)) {
        return true
      }
    }
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
