<template>
  <v-col
    cols="12"
    sm="12"
  >
    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        md="9"
      >
        <!--Title Bar - Only Shown On List View-->
        <title-bar :title-value="'FAQs'" />

        <v-container fluid>
          <v-row dense>
            <v-col
              cols="12"
              sm="12"
            >
              <p class="text-h6 nsfgrey--text text-center">
                Frequently Asked Questions are listed below.
              </p>
              <p class="text-h6 nsfgrey--text text-center">
                Click on a question to see its answer.
              </p>
              <p class="text-h6 nsfgrey--text text-center">
                If you can’t find what you need to know, send us an email and we’ll get in touch ASAP.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <div
          v-for="(item,i) in faqsStore.faqList"
          :key="i"
        >
          <div v-if="item.flag_type === 1 || item.flag_type === 4">
            <span
              v-if="item.flag_type === 4"
              class="text-h6 nsfgrey--text font-weight-bold"
            >{{ item.title }}</span>
            <v-expansion-panels
              v-else
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle-1 primary--text">
                  {{ item.title }}
                  <template #actions>
                    <v-icon
                      small
                      color="primary"
                    >
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-4">
                  <div v-html="item.text" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="3"
      >
        <v-row dense>
          <v-col
            cols="12"
            sm="12"
          >
            <!--Title Bar - Only Shown On List View-->
            <title-bar :title-value="'Links'" />
            <div
              v-for="(item,i) in faqsStore.faqList"
              :key="i"
            >
              <v-list-item v-if="item.flag_type === 2">
                <v-list-item-content>
                  <a
                    :href="item.text"
                    target="blank"
                  >{{ item.title }}</a>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-if="1 === 2"
          dense
        >
          <v-col
            cols="12"
            sm="12"
          >
            <!--Title Bar - Only Shown On List View-->
            <title-bar :title-value="'Posts'" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';

export default {
  name: 'Faq',
  components: {
    TitleBar,
  },
  mixins: [mixin],
  data() {
	  return {
	  	
	  };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      faqsStore: (state) => state.faqsStore,
    }),
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true)
      await this.$store.dispatch('faqsStore/list')
      this.$store.dispatch('progressStore/set',false)
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
